import type { HTMLInputTypeAttribute } from "react";

interface Props {
  name: string;
}

export interface FormFieldStructure {
  type: "array-empty" | "checkbox-group" | HTMLInputTypeAttribute | "json" | "file" | "boolean";
}

export const FormFieldIdentifier = ({ type, name }: Props & FormFieldStructure) => {
  return (
    <>
      <input type="hidden" name={`__form.${name}.type`} value={type} />
    </>
  );
};

export default FormFieldIdentifier;
